<template>
  <div>
    <custom-breadcrumb
      :pageTitle="$t('Hierarchy')"
      :breadcrumb="[
        {
          text: $t('Hierarchy'),
          active: true,
        },
      ]"
  ></custom-breadcrumb>
    <tree-search-tree />
  </div>
</template>

<script>
import TreeSearchTree from './components/TreeSearchTree.vue'
import CustomBreadcrumb from '@/views/components/breadcrumb/CustomBreadcrumb.vue'

export default {
  components: {
    TreeSearchTree,
    CustomBreadcrumb
  },
}
</script>

<style lang="scss">
@import "@core/scss/vue/libs/tree.scss";
</style>